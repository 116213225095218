import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { XpayService } from '@community/shared/xpay.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { PayService } from '@pay/pay/pay.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TotalBreakdownService } from '@community/shared/total-breakdown.service';
import { PaymentModuleService } from '@community/shared/payment-module.service';
import { PayType } from '@shared/interfaces/payType';
import { Subject, Subscription } from 'rxjs';
import { VariableAmount } from '@shared/interfaces/variable-amount';
import {
  PrivateDonationCartInfo,
  PublicDonationCartInfo
} from '@shared/interfaces/cartInfo';
import { AmountBreakdown } from '@shared/interfaces/amount-breakdown';
import { ModuleScope } from '@shared/interfaces/module-scope';
import { MODULE_SCOPE } from '@shared/module-scope';

@Component({
  selector: 'app-variable-amount-widget',
  templateUrl: './variable-amount-widget.component.html',
  styleUrls: ['./variable-amount-widget.component.scss']
})
export class VariableAmountWidgetComponent implements OnInit, OnDestroy {
  communityId: string = null;
  variableAmountTemplate: VariableAmount;
  minAmount = 0;
  variableAmountForm: FormGroup;
  membershipId: string;
  ngUnsubscribe = new Subject();
  alertMessage: string;
  timeout: any;
  submitted = false;
  amountBreakdown: AmountBreakdown;
  queryParams: Params;
  queryParamsSub: Subscription;

  constructor(
    private xpayService: XpayService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private payService: PayService,
    private fb: FormBuilder,
    private breakdownService: TotalBreakdownService,
    private paymentModuleService: PaymentModuleService,
    private route: ActivatedRoute,
    @Inject(MODULE_SCOPE) private scope: ModuleScope
  ) {}

  ngOnInit() {
    this.communityId = this.xpayService.communityId;

    this.variableAmountTemplate = this.activatedRoute.snapshot.data.va;

    if (this.variableAmountTemplate) {
      this.amountBreakdown = this.breakdownService.initTotalBreakdown(
        this.variableAmountTemplate,
        PayType.VARIABLEAMOUNT
      );

      this.initDonationForm(this.variableAmountTemplate.is_active);

      // set payment module data
      this.paymentModuleService.changePaymentModule({
        name: this.variableAmountTemplate.name
      });

      this.patchMembershipId();
    }

    this.queryParamsSub = this.route.queryParams.subscribe(
      params => (this.queryParams = params)
    );
  }

  get f() {
    return this.variableAmountForm;
  }

  get membershipControl() {
    return this.f.controls.membership_id;
  }

  get amountControl() {
    return this.f.controls.amount;
  }

  patchMembershipId() {
    this.xpayService.memberships$.subscribe(el => {
      if (el && el.length > 0) {
        this.membershipId = el[0].member_id;
        this.variableAmountForm.patchValue({
          membership_id: { membership_id: this.membershipId }
        });
      }
    });
  }

  initDonationForm(isActive: boolean) {
    this.variableAmountForm = this.fb.group({
      amount: ['', [Validators.required]],
      membership_id: []
    });

    if (!isActive) {
      this.membershipControl.disable();
      this.amountControl.disable();
    }
  }

  pay(variableAmountTemplate: VariableAmount) {
    this.submitted = true;
    if (this.variableAmountForm.valid) {
      // clear stored pay data
      this.payService.clearLocalStoragePayData();

      this.amountBreakdown.grand_total_amount = +this.amountControl.value;
      this.amountBreakdown.cash.grand_total_amount = +this.amountControl.value;
      this.amountBreakdown.kiosk.grand_total_amount = +this.amountControl.value;
      this.amountBreakdown.cash.cash_collection_fees_amount = 0;
      this.breakdownService.changeTotalBreakdownInfo(this.amountBreakdown);

      if (variableAmountTemplate.require_member_id) {
        const variableAmountCartInfo: PrivateDonationCartInfo = {
          amount: this.amountControl.value,
          currency: this.breakdownService.currentPaymentCurrency,
          variable_amount_id: this.variableAmountTemplate.id,
          membership_id: this.membershipControl.value.membership_id
        };

        // change cart info
        this.payService.changeCartInfo<PrivateDonationCartInfo>(
          variableAmountCartInfo
        );

        // change pay type
        this.payService.changePayType(PayType.VARIABLEAMOUNT);
      } else {
        const variableAmountCartInfo: PublicDonationCartInfo = {
          amount: this.amountControl.value,
          currency: this.breakdownService.currentPaymentCurrency,
          variable_amount_id: this.variableAmountTemplate.id
        };

        this.breakdownService.currentTotalBreakdown.sub_total_amount = this.breakdownService.currentTotalBreakdown.grand_total_amount;

        // change cart info
        this.payService.changeCartInfo<PublicDonationCartInfo>(
          variableAmountCartInfo
        );

        // change pay type
        this.payService.changePayType(PayType.VARIABLEAMOUNT);

        // this.router.navigateByUrl(`i/community/${this.communityId}/pay`);
      }

      this.payService.changeSource(this.queryParams.utm_source);

      const modeURLSegment = this.scope.mode ? `${this.scope.mode}/` : '';
      this.router.navigateByUrl(
        `${modeURLSegment}${this.scope.module}/${this.communityId}/pay`
      );
    }
  }

  onInputKeyPress(event: KeyboardEvent): void {
    const allowedChars = /[0-9]/;
    if (!allowedChars.test(event.key)) {
      event.preventDefault();
    }
  }

  trackConversion() {
    this.payService.trackConversion();
  }
  ngOnDestroy(): void {
    this.queryParamsSub.unsubscribe();
  }
}
